.IFTextInputStyle {
  height: 2rem;
  text-align: left;
  padding-left: 0.5rem;
}

.subtitleText {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.subtitleContainer {
  margin-bottom: 0.75rem;
}

.ButtonVisible {
  opacity: 1;
  max-width: 7rem;
  overflow: 0;
  transition: all 2s;
}

.ButtonFade {
  opacity: 0;
  max-width: 0;
  overflow: hidden;
  margin-left: 0 !important;
  transition: opacity 0.5s, max-width 1s, margin-left 1s;
}
