.wrapper {
  border-radius: 5px;
  margin: auto;
  display: flex;
  align-items: center;
  height: 3rem;
}
.container {
  display: flex;
  flex-direction: row;
  flex: 1 1 0px;
  flex-grow: 1;
  justify-content: center;
}
.icon {
  margin-left: 0.625rem;
  width: 1.25rem;
  min-height: 1.25rem;
}
.leftText {
  flex: 1;
  margin-left: 0.625rem;
  max-width: 12rem;
  height: 1.5rem;
}
.rightText {
  flex: 1;
  margin-left: auto;
  margin-right: 1rem;
  max-width: 12rem;
}
