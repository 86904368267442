.Container {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
}

.LeftContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1.5;
  flex-basis: 0;
  justify-content: space-evenly;
}

.RightContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: space-between;
  align-items: flex-end;
}
