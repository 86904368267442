.WaypointContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.Container {
  height: 100%;
  width: 100%;
}
.Text {
  font-size: 14px;
  font-weight: 600;
}
.StatusContainer {
  display: flex;
  width: 7.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-radius: 1em;
  justify-content: center;
  align-items: center;
  z-index: 0;
}
.ItemContainer {
  padding: 2px 8px 8px 2px;
}
