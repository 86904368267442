.Container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0.5rem;
  padding-bottom: 1rem;
}

.LastUpdatedContainer {
  display: flex;
  justify-content: flex-end;
  font-size: 0.75rem;
}

.New {
  font-weight: 600;
  align-self: flex-end;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.NewContainer {
  border-radius: 4px;
  margin-right: 0.5rem;
}

.UserDetailsContainer {
  display: flex;
  flex-direction: row;
}

.UserCredentialsContainer {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.NameContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;
}

.EmailContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
}

.NameText {
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
}

.EmailText {
  text-overflow: ellipsis;
  overflow: hidden;
}

.UserAvatarContainer {
  position: relative;
  display: flex;
}

.StatusContainer {
  border-radius: 50%;
  position: absolute;
  bottom: -0.1rem;
  right: 0.05rem;
}

.InviteStatusContainer {
  display: flex;
  border-radius: 1em;
  justify-content: center;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.TokenCountContainer {
  display: flex;
  flex: 1;
  justify-content: end;
}
.Chip {
  border-radius: 12px;
  padding-left: 4px;
  padding-right: 4px;
  min-width: 17px;
  align-content: center;
  align-items: center;
  align-self: center;
}
.TokenCountText {
  text-align: center;
  font-size: 12px;
}
