.Container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0.5em;
  padding-bottom: 1rem;
}

.LastUpdatedContainer {
  display: flex;
  justify-content: flex-end;
}

.UserDetailsContainer {
  display: flex;
  flex-direction: row;
  padding-left: 0.1rem;
}

.UserCredentialsContainer {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
}
