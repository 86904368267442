.Container {
  height: 100%;
  width: 100%;
}
.ItemContainer {
  overflow-x: hidden;
}

.SkeletonContainer {
  margin-bottom: 1rem;
}

.DividerContainer {
  display: flex;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.Divider {
  width: 100%;
}
