.Container {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 0.5em;
}

.RightDetailsContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 15;
}

.MarkerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -2px;
}

.LastUpdatedContainer {
  display: flex;
  justify-content: flex-end;
  height: 1em;
}

.StationNameContainer {
  display: flex;
  padding-left: 0.5em;
}

.BottomContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ConnectorsContainer {
  margin-left: 0.5em;
}
