.TextTitle {
  font-size: 1.25rem;
  font-weight: 500;
}

.InputContainer {
  margin-bottom: 1.5rem;
}
.LgInput {
  margin-top: 0.5rem;
  min-width: 16rem;
  max-width: 16rem;
  text-align: left;
  height: 2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.selectPicker {
  border-radius: 5px;
  width: 16rem;
}
.FormContainer {
  margin-top: 0.5rem;
}
