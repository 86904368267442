.Container {
  width: 100%;
  position: relative;
}

.AddButton {
  height: 32px;
  width: 100%;
  border: 1px dashed;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  background-color: transparent;
}

.IconContainer {
  display: flex;
  align-self: center;
}

.SelectPicker {
  width: 100%;
  position: absolute;
  top: 0;
  opacity: 0;
  pointer-events: none;
}
