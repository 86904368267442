.Container {
  display: flex;
  min-height: 42px;
  position: relative;
  align-items: center;
  padding-right: 10px;
  align-content: center;
  justify-content: center;
  cursor: pointer;
}

.Container:hover {
  opacity: 0.5;
}

.Text {
  font-size: 0.875rem;
  font-weight: 600;
}

.PopperContainer {
  display: flex;
  border-radius: 0.5rem;
  width: 300px;
  height: 60px;
  justify-content: center;
  align-items: center;
}

.Slider {
  width: 80%;
}
