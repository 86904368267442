.Container {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  height: 1.5rem;
}

.IconContainer {
  display: flex;
  cursor: pointer;
  margin-right: 0.5rem;
  justify-content: center;
  flex-direction: column;
}

.NameContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Text {
  font-weight: 400;
  font-size: 14px;
  align-self: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ToolTipOverflownText {
  font-weight: 400;
  font-size: 14px;
  min-width: 0;
  white-space: nowrap;
}

.UsersCountContainer {
  display: flex;
  flex-grow: 0.5;
  flex-basis: 0;
  justify-content: flex-end;
  align-items: center;
}
.Icon {
  font-size: 16px !important;
}
