.container {
  padding-top: 4px;
  width: 100%;
  height: 100%;
}

.WaypointContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
