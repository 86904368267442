.Divider {
  width: 100% !important;
  align-self: center;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.Container {
  display: flex;
  margin-bottom: 0.5rem;
}
.CurrentVersion {
  font-size: 1rem;
}
.ChipContainer {
  display: flex;
  flex-wrap: wrap;
}
.LocalListText {
  font-weight: 600;
  font-size: 1rem;
  align-self: center;
  margin-bottom: 1rem;
}
.UpdateTypeText {
  font-size: 14px;
  align-self: center;
  margin-bottom: 0.5rem;
}
.UpdateTypeTextSP {
  width: 8.5rem;
  margin-bottom: 1rem;
}
.LocalList {
  font-size: 14px;
  align-self: center;
  margin-bottom: 8px !important;
}
.LocalListWrapper {
  border-radius: 5px;
  border-width: 1px;
  padding: 1rem;
}
.LocalListChipContainer {
  height: 5rem;
  overflow: scroll;
}
.ButtonContainer {
  margin-top: 2.5rem;
  display: flex;
  justify-content: flex-end;
}
.SkeletonChip {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
.DatePicker {
  margin-bottom: 1rem;
  width: 8.5rem;
}
