.ChargingSpecsAdd {
  margin-top: 0.5rem;
  width: 100%;
}

.EmptyContainer {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.PrivacyRulesListWrapper {
  display: flex;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: column;
  overflow: hidden;
}

.PrivacyRulesListWrapperEditing {
  display: flex;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: column;
}

.ListWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 0.5rem;
}

.ListWrapperEditing {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 0.5rem;
  margin-right: -1rem;
  width: calc(100% + 1rem) !important;
}

.ListItem {
  height: 100%;
  width: calc(100% - 1rem) !important;
}
