.PageContainer {
  display: flex;
  width: 100vw;
}

.RightColumnContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 1px;
}

.ContentContainer {
  display: flex;
  flex-grow: 0;
  height: 100vh;
}
