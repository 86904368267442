.SelectPicker {
  width: 14rem;
}

.Container {
  display: flex;
  padding-left: 0.25rem;
  flex-direction: row;
  align-items: center;
}

.IconContainer {
  display: flex;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
}

.ChademoStyle {
  width: 24px;
}

.Type2Style {
  width: 24px;
}

.SchukoStyle {
  width: 24px;
}

.ComboCCSStyle {
  width: 20px;
}

.Text {
  font-size: 0.75rem;
  font-weight: 400;
  padding-left: 0.625rem;
}
