.container {
  padding-top: 4px;
  padding-bottom: 4px;
  height: 100%;
}

.WaypointContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.newLogsIndicator {
  position: absolute;
  z-index: 1;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0px 0px 4px #00000040;
  padding-left: 8px;
  padding-right: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: -36px;
  flex-direction: row;
  background-color: #f8f8f8;
  display: flex;
  visibility: hidden;
  pointer-events: none;
}

.animateInNewLogsIndicator {
  visibility: visible;
  pointer-events: auto;
  animation: animateLogsIn 0.3s forwards;
}

.animateOutNewLogsIndicator {
  visibility: visible;
  pointer-events: none;
  animation: animateLogsInOut 0.3s forwards;
}

@keyframes animateLogsIn {
  0% {
    transform: translate(-50%, 0px);
  }
  100% {
    transform: translate(-50%, 40px);
  }
}

@keyframes animateLogsInOut {
  0% {
    transform: translate(-50%, 40px);
  }
  100% {
    visibility: hidden;
    pointer-events: none;
    transform: translate(-50%, 0px);
  }
}

.newLogsIndicator:hover {
  background-color: #f5f5f5;
}
