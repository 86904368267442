.wrapper {
  border-radius: 5px;
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  flex: 1 1 0px;
  flex-grow: 1;
  justify-content: center;
  margin-right: 1rem;
  margin-left: 0.75rem;
}

.item {
  margin: auto;
  flex-grow: 1;
  flex-basis: 0;
  align-self: center;
  justify-content: center;
}
.iconText {
  display: flex;
}
.icon {
  width: 1.5rem !important;
  height: 1.5rem !important ;
}
.text {
  width: 5rem;
  height: 1.5rem !important;
  margin: auto !important;
  margin-left: 1rem !important;
}

.middle {
  max-width: 7rem;
  height: 1.5rem !important;
}

.rightText {
  height: 1.5rem;
}
