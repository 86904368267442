.Container {
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.LeftContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  height: 1.5rem;
}

.RightContainer {
  display: flex;
  flex-grow: 0.7;
  flex-basis: 0;
  height: 1.5rem;
  justify-content: flex-end;
}

.TextContainer {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
}

.Name {
  width: 9rem;
  height: 1.25rem !important;
}
