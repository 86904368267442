.IFButton {
  position: relative !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  padding: 0.313rem 0.313rem !important;
  line-height: 18px;
  font-weight: 500 !important;
  border-radius: 4px !important;
  border: 1px solid #27a3ac;
  width: 100%;
  outline: 0 !important;
  overflow: hidden !important;
  z-index: 1 !important;
  cursor: pointer;
  font-family: 'ProximaNova';
  text-transform: unset !important;
}

.fill {
  background: none;
}

.filled {
  position: relative !important;
  text-decoration: none !important;
  overflow: hidden !important;
  cursor: pointer !important;
}

.IconContainer {
  display: flex;
}
