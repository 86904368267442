.EmptySet {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.WaypointContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.EmptyText {
  margin-top: 1rem;
  font-weight: 600;
  font-size: 1rem;
  align-items: center;
  text-align: center;
}

.NoSitesIcon {
  align-self: center;
  width: 56px;
}
.wrapper {
  height: 100%;
}
