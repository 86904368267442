.SelectPicker {
  width: 5.2rem;
}

.Container {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.Text {
  font-size: 14px;
}
