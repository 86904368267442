.AccessGroupListHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.HeaderText {
  font-size: 0.875rem;
  font-weight: 600;
}

.AccessGroupsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
