.Container {
  width: 340px;
  display: flex;
  flex-direction: column;
}

.StationHeader {
  width: 330px;
  border-radius: 15px;
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 8px;
  align-content: center;
  align-items: center;
}

.StationHeaderText {
  font-size: 16px;
  font-weight: 600;
  padding-left: 8px;
}

.StationBody {
  width: 340px;
  max-height: 300px;
  border-radius: 15px;
  padding: 5px;
}

.ChargePoint {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 5px;
}

.ChargePointName {
  font-weight: 600;
  margin-left: 0.75rem;
  margin-right: 8px;
}

.ConnectorText {
  margin: 0;
}

.FlashIcon {
  width: 24px;
  height: 24px;
  align-self: center;
}

.ChargerTypeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex: 3;
  height: inherit;
}

.ConnectorStatusText {
  flex: 2;
  text-align: center;
  margin: 0;
}
