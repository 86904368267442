.Container {
  width: 100%;
  display: flex;
  margin-top: 0.5rem;
  flex-grow: 1;
  flex-basis: 0;
  height: 100%;
  max-height: 100%;
}
.ListTitleContainer {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
}
.TitleContainer {
  margin-bottom: 0.5rem;
}
.Title {
  font-weight: 600;
  font-size: 14;
}

.SubscriptionList {
  margin-right: 1rem;
  padding: 1rem;
  padding-left: 1rem;
  flex-basis: 0;
}
.AccessGroupList {
  margin-right: 1rem;
  padding: 1rem;
  flex-basis: 0;
}
.ScheduleList {
  margin-right: 1rem;
  padding: 1rem;
  padding-left: 1rem;
  flex-basis: 0;
}
