.Text {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  color: rgb(0, 0, 0);
}

.TextField {
  margin-bottom: 1rem;
}

.TextInput {
  min-width: 12.5rem;
  max-width: 23rem;
  height: 2rem;
  text-align: left !important;
  font-size: 0.875rem !important;
  padding-left: 0.5rem;
}

.Button {
  display: flex;
  justify-content: right;
  margin-top: 1rem;
}

.Container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
