.Container {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 0.5rem;
}

.LeftContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1.5;
  flex-basis: 0;
  justify-content: space-evenly;
}

.RightContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: space-between;
  align-items: flex-end;
}

.ReferenceText {
  font-size: 14px;
  font-weight: 500;
}

.UidText {
  font-size: 12px;
}

.VisualNumberText {
  font-size: 12px;
}

.StatusContainer {
  display: flex;
  border-radius: 1em;
  justify-content: center;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.StatusText {
  font-size: 0.75rem;
}
