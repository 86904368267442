.Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.Fields {
  display: flex;
  margin-bottom: 0.5rem;
}
.TextName {
  font-size: 16px;
  font-weight: 600;
}
.NameInput {
  margin-left: 1rem;
  min-width: 6rem;
}
.CurrentInput {
  margin-left: 1rem;
}
.Container {
  height: 100% !important;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
  height: 30rem;
  margin-bottom: 2rem;
}

.Button {
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 3rem;
}
