.Label {
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.Input {
  text-align: start;
  text-indent: 0.625rem;
  background-color: transparent;
  height: 32px;
  width: 172px;
}

.InputContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.8rem;
}

.Submit {
  margin-right: auto;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-top: 1rem;
}

.Next {
  margin-right: auto;
  display: flex;
  justify-content: center;
  margin-left: auto;
}

.InputWrapperRight {
  display: flex;
  position: relative;
  max-width: 550px;
  flex-direction: row;
  max-height: 500px;
  right: 574px;
  transition: right 400ms ease-in-out, max-width 400ms ease-in-out;
}

.InputWrapperLeft {
  display: flex;
  position: relative;
  max-width: 320px;
  flex-direction: row;
  max-height: 500px;
  right: 0rem;
  transition: right 400ms ease-out, max-width 400ms ease-out;
}

.DividerWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.DividerContainer {
  width: 45%;
  align-self: center;
}

.DividerText {
  width: 10%;
  text-align: center;
  font-weight: 600;
}

.DropZone {
  height: 10rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.DropZoneSelected {
  height: 10rem;
  margin-bottom: 1.5rem;
  margin-top: 0rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  transition: margin-top 0.5s;
}

.FadeOut {
  opacity: 0;
  max-height: 0px;
  margin: 0px;
  transition: max-height 0.5s, opacity 0.2s, margin 0.5s;
  pointer-events: none;
}

.FadeIn {
  opacity: 1;
  max-height: 250px;
  transition: max-height 0.5s, opacity 1s;
}

.AnimateHeightOut {
  max-height: 137px;
  transition: max-height 600ms ease-out;
  pointer-events: none;
}

.AnimateHeightIn {
  max-height: 350px;
  transition: max-height 400ms ease-in-out;
}

.AnimateDropZoneHeightOut {
  max-height: 137px;
  transition: max-height 600ms ease-out;
  pointer-events: none;
}

.AnimateDropZoneHeightIn {
  max-height: 350px;
  transition: max-height 600ms ease-in-out;
}

.CloseIconContainer {
  position: absolute;
  top: 2.5rem;
  right: 8rem;
}

.CloseIcon {
  width: '20px';
  height: '20px';
  cursor: pointer;
}

.EmailDivShow {
  width: 320px;
  flex: 0 0 100%;
  position: relative;
  right: 0;
  opacity: 1;
  margin-right: 1.5rem;
}

.EmailContainer {
  display: flex;
  margin-bottom: 1rem;
}

.SpecificationsContainerVisible {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  flex: 0 0 100%;
  left: 0rem;
  opacity: 1;
}

.SpecificationsWrapper {
  display: flex;
  flex-direction: column;
}
