.Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 2.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
.SitesText {
  font-size: 1rem;
  font-weight: 600;
}

.TextContainer {
  display: flex;
  position: relative;
  justify-content: center;
}

.LeftContainer {
  display: flex;
  flex: 1;
}

.IconContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
