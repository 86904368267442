.container {
  display: flex;
  flex-direction: row;
  flex: 1 1 0px;
  justify-content: space-between;
  height: 64px;
}
.accordion {
  border-radius: 5px;
  min-height: 3.5rem;
}

.accordionStationWrapper {
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;
  padding-left: 1rem;
  padding-top: 1rem;
}

.accordionTextPower {
  display: flex;
  flex-direction: column;
  flex-grow: 0.5;
  flex-basis: 0;
  align-items: center;
  padding-top: 1.05rem;
}

.accordionDuration {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  flex-grow: 0.8;
  flex-basis: 0;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
}

.accordionDurationText {
  font-size: 8px;
  font-weight: 400;
  margin-left: 0;
  margin-top: 0.375rem;
  padding-bottom: 0.5rem;
  align-items: center;
  width: 6rem;
}

.accordionTextEnergyConsumption {
  font-size: 8px;
  font-weight: 400;
  margin-left: 0;
  padding-bottom: 0.5rem;
  margin-top: 0.375rem;
  width: 6rem;
}

.durationText {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding-right: 6.125rem;
}

.stationText {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
}

.stationName {
  padding-top: 1rem;
  margin-bottom: -0.2rem;
  width: 8rem;
  font-size: 4px;
}

.chargePointName {
  font-size: 8px;
  padding-bottom: 0.5rem;
  width: 7.5rem;
}

.amountContainer {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  flex-grow: 0.5;
  flex-basis: 0;
  align-items: center;
  justify-content: center;
}

.amount {
  font-size: 8px;
  font-weight: 600;
  margin: 0;
  margin-top: 0.375rem;
  padding-bottom: 0.5rem;
  width: 6rem;
  height: 6rem;
}

.icon {
  width: 1.2rem !important;
  height: 1.2rem !important ;
}

.stationIcon {
  width: 1.8rem !important;
  height: 1.8rem !important ;
}
