.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 3.5rem;
}

.ChargePointName {
  font-size: 1rem;
  text-transform: none;
  margin-bottom: 0.125rem;
  width: 9rem;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ConnectorsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ConnectorsNumber {
  font-size: 0.875rem;
  font-weight: 400;
}

.ConnectorContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 0.75rem;
}

.ConnectorContainerInactive {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.StatusBox {
  width: 0.675rem;
  height: 0.675rem;
  border-radius: 2px;
  margin-left: 0.25rem;
}
.inactive {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 0.875rem;
  text-align: center;
  text-transform: none;
}
.ConnectingContainer {
  display: flex;
  justify-content: center;
}
.spinner {
  width: 1rem;
  text-align: center;
}

.spinner > div {
  width: 0.25rem;
  height: 0.25rem;

  border-radius: 50%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
