.Container {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 1rem;
}

.AvatarContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 0.1;
  padding-left: 1rem;
}

.NameContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1.8;
}

.PermissionsContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.EditContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  padding-right: 1.5rem;
}
.AvatarNameContainer {
  flex-grow: 1;
  display: flex;
}
