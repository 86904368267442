.Buttons {
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.ModalTitleBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.ExitIcon {
  cursor: pointer;
  width: 1.5em;
  height: 1.5em;
}

.ExitIconDisabled {
  cursor: default;
  width: 1.5em;
  height: 1.5em;
}

.ModalStyle {
  width: 400;
  bgcolor: 'background.paper';
  border: '2px solid #000';
  p: 2;
  px: 4;
  pb: 3;
}

.Title {
  font-size: 1.25rem;
  font-weight: 600;
  color: black;
}

.TitleContainer {
  display: flex;
  flex-direction: row;
}

.BackIconContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 0.5rem;
  margin-left: -8px;
  cursor: pointer;
}
