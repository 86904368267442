.Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.TenantUsersContainer {
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 65vw;
  height: 75vh;
}

.HeaderContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-right: 1rem;
  padding-left: 1rem;
}

.TenantUserTextContainer {
  flex-grow: 1.25;
  display: flex;
  flex-direction: column-reverse;
}

.PermissionsTextContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column-reverse;
}

.HeaderButtonContainer {
  align-items: center;
  align-items: center;
  align-self: center;
  margin-top: 1rem;
}

.HeaderTitles {
  margin-block-start: 0;
  margin-block-end: 0;
  bottom: 0rem;
  padding-left: 1rem;
  font-size: 1rem;
}

.InviteButton {
  height: 3rem;
  align-self: flex-end;
}

.LoadingIndicator {
  text-align: center;
  width: 4rem;
}

.LoadingContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
}
