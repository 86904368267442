.ListItemSummary {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.ListItemDetails {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
}

.CancelButton {
  font-size: 0.75rem;
  margin-right: 1rem;
}

.UpdateButton {
  font-size: 0.75rem;
  margin-right: 1rem;
}

.AvatarNameEmail {
  display: flex;
  flex-direction: row;
  flex-grow: 0.97;
  flex-basis: 0;
}

.AvatarIcon {
  align-self: center;
}

.Name {
  font-size: 0.875rem;
  margin: 0.25rem;
}

.Email {
  margin: 0.25rem;
}

.Role {
  font-size: 0.875rem;
  align-content: center;
  display: flex;
  flex-basis: auto;
  padding: 0.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.5rem;
}

.PenIcon {
  font-size: 1rem;
  align-self: center;
  margin-right: 1rem;
}

.DeleteIcon {
  font-size: 1.2rem;
  align-self: center;
  margin-right: 1rem;
}

.CheckPicker {
  flex-grow: 1;
  flex-basis: 0;
}

.Container {
  align-content: center;
}

.NameEmail {
  display: flex;
  flex-direction: column;
  margin-left: 0.875rem;
  justify-content: center;
}

.LoadingIndicator {
  text-align: center;
  width: 3rem;
}

.PermissionsContainer {
  align-items: center;
  font-size: 0.875rem;
  align-content: center;
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-basis: 0;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
