.Title {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.25rem;
}

.HeaderBarWrappingDiv {
  width: 100%;
  min-height: 4rem !important;
  display: flex;
  flex-direction: row;
}

.TitleWrappingDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1rem;
  flex-grow: 1;
}

.AvatarWrappingDiv {
  display: flex;
  align-self: center;
  width: unset;
  border-width: 0;
  margin-right: 1em;
  max-width: 5em !important;
  cursor: pointer;
}

.LogoutButton {
  height: 2rem;
  width: 5rem;
  font-size: 1rem;
  justify-content: center;
  display: flex;
}

.UsernameStyling {
  display: flex;
  justify-content: center;
}
