.MapFilter {
  width: 905px;
  margin-right: calc(50vw - 452px);
  z-index: 2000;
}

.HeatMapFilter {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 905px;
  top: 20px;
}

.StationsFilter {
  width: inherit;
}

.MapViewToggle {
  border-radius: 2px;
  position: absolute;
  bottom: 120px;
  right: 10px;
  z-index: 2000;
}

.SearchBoxContainer {
  position: relative;
  display: flex;
  padding-left: 2rem;
  padding-right: 2rem;
  z-index: 2000;
}

.TopDetailsContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 20px;
  justify-content: space-between;
  width: calc(100vw - 56px);
}
