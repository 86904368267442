.Container {
  display: flex;
  width: 100%;
  height: 1.5rem;
  margin-bottom: 0.5rem;
}

.LeftContainer {
  display: flex;
  flex-grow: 3;
  flex-basis: 0;
  min-width: 0;
}

.RightContainer {
  display: flex;
  flex-grow: 2.5;
  flex-basis: 0;
  justify-content: space-between;
}

.IconContainer {
  margin-right: 0.25rem;
  align-self: center;
  display: flex;
  cursor: pointer;
}

.Icon {
  font-size: 16px !important;
}

.Text {
  font-weight: 400;
  font-size: 14px;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.NameText {
  font-weight: 400;
  font-size: 14px;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 0.5rem;
}

.TextContainer {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
}

.DateTextContainer {
  align-self: center;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.Divider {
  height: 12px !important;
  width: 1px !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
  align-self: center;
}

.TextTimeContainer {
  display: flex;
  justify-content: center;
}

.ToolTipOverflownText {
  font-weight: 400;
  font-size: 14px;
  min-width: 0;
  white-space: nowrap;
}
