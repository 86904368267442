.UpdateText {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0px;
}

.DropZone {
  position: relative;
  height: 12rem;
  margin-bottom: 1.5rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.Container {
  display: block;
  flex-direction: column;
}

.CurrentFirmware {
  padding-bottom: 1.5rem;
}

.CloseIconContainer {
  position: absolute;
  top: 3rem;
  right: 16rem;
}

.CloseIcon {
  width: '20px';
  height: '20px';
  cursor: pointer;
}

.CloseIconDisabled {
  width: '20px';
  height: '20px';
  cursor: default;
}

.DividerWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.DividerWrapperFade {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}

.DividerContainer {
  width: 45%;
  align-self: center;
}

.DividerText {
  width: 10%;
  text-align: center;
  font-weight: 600;
}

.FadeOut {
  opacity: 0;
  max-height: 0px;
  transition: max-height 0.5s, opacity 0.2s;
}
.FadeIn {
  opacity: 1;
  max-height: 200px;
  transition: max-height 0.5s, opacity 0.2s;
}
.InputContainer {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}

.InputContainerFade {
  display: flex;
  flex-direction: row;
}

.Input {
  text-align: start;
  text-indent: 0.625rem;
  background-color: transparent;
  height: 1.75rem;
  width: 20rem;
  margin-left: 0.5rem;
}

.Label {
  font-size: 0.875rem;
}

.URLTextContainer {
  display: flex;
  align-items: center;
}

.BottomContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.UploadProgressContainer {
  flex-grow: 1;
  font-weight: 500;
}
