.SideNavBarWrappingDiv {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 3.5rem;
}

.SideNavBarWrappingDivMap {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 3.5rem;
  z-index: 2000;
}

.logoWrappingDiv {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 0.5rem;
  width: 3.5rem;
  height: 4.5rem;
  padding-top: 0.5rem;
}

.logo {
  max-width: 80%;
  max-height: 80%;
}

.iconContainer {
  display: flex;
  width: 3.5rem;
  height: 2.5rem;
  flex-direction: column;
  border-radius: 1rem;
  align-self: center;
  flex-grow: 1;
  flex-basis: 0;
  margin-right: 0.4rem;
  align-items: center;
  justify-content: center;
}

.flashContainer {
  width: 26px;
  filter: invert(1);
}

.iconsWrappingDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100% !important;
  border-radius: 0px 5px 0px 0px;
  width: 100%;
}
.BottomIconsWrappingDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.bottomContainer {
  height: 100%;
  width: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 1rem;
}

/* header-bar */
.Title {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.25rem;
}

.HeaderBarWrappingDiv {
  width: 100%;
  min-height: 4rem !important;
  display: flex;
  flex-direction: row;
}

.TitleWrappingDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1rem;
  flex-grow: 1;
}

.AvatarWrappingDiv {
  display: flex;
  align-self: center;
  width: unset;
  border-width: 0;

  cursor: pointer;
}

.LogoutButton {
  height: 2rem;
  width: 5rem;
  font-size: 1rem;
  justify-content: center;
  display: flex;
}

.Username {
  display: flex;
  justify-content: center !important;
  text-align: center;
  font-size: 0.875rem !important;
}

.IconDiv {
  display: flex;
  width: 100%;
  padding-top: 1rem;
}
