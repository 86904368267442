.Container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.VisualNumberContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: flex-start;
  align-items: center;
}

.SkeletonContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: center;
  align-items: center;
}

.EditContainer {
  display: flex;
  flex-grow: 0.7;
  flex-basis: 0;
  justify-content: center;
  align-items: center;
}
