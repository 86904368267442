.Container {
  display: flex;
  flex-direction: row;
  height: 25rem;
}

.ActionsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.ButtonsContainer {
  display: flex;
  flex-basis: 0;
  margin-left: 1rem;
  justify-content: flex-end;
  flex-direction: column;
}

.TooltipIcons {
  align-self: center;
  margin-top: 1rem;
}

.ListWrapper {
  height: 25rem;
  width: 100%;
}

.ListWrapperEditing {
  height: 22rem;
  width: 100%;
}

.AddButtonWrapper {
  padding-top: 1rem;
  margin-right: 1rem;
  max-height: 48px;
}
