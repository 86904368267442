.Container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.LeftContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  margin: 1rem;
}

.RightContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 0;
  flex-basis: 0;
  margin-right: 1rem;
}

.RightContainerEdit {
  display: flex;
  height: 100%;
  flex-grow: 0;
  flex-basis: 0;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
}

.TitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ChargePointNameContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 2.5px;
}

.ChargePointNameWrapper {
  display: flex;
  flex-direction: row;
}

.ChargePointStationNameContainer {
  display: flex;
  align-items: center;
  border-radius: 5px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 8px;
  padding-right: 12px;
  z-index: 0;
  cursor: pointer;
}

.ChargePointName {
  display: flex;
  align-items: center;
  border-left-color: transparent;
  border-radius: 0px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  padding-left: 24px;
  padding-right: 8px;
  margin-left: -16px;
}

.ChargePointNameEdit {
  padding-left: 16px;
  padding-right: 0px;
  padding-top: 0.5px;
  padding-bottom: 0.5px;
}

.NameText {
  font-size: 1.25rem;
}

.TextInputStyle {
  font-size: 1.25rem !important;
}

.DateContainer {
  display: flex;
  align-items: center;
  padding-right: 1rem;
  font-size: 12px;
}

.ChargePointUidContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.ChargingIcon {
  width: 20px;
  height: 20px;
  margin-left: 0.25rem;
  margin-right: 0.5rem;
}

.UIDText {
  margin-top: 2px;
  font-size: 0.875rem;
  font-weight: 400;
}

.ConnectorsTextContainer {
  padding-top: 0.5rem;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
  padding-right: 1rem;
  padding-left: 1rem;
  height: 28px;
}

.ConnectorsContainer {
  display: flex;
  flex-grow: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  flex-basis: 0;
}

.TabsContainer {
  display: flex;
  padding-left: 1rem;
  margin-bottom: -1.5px;
  padding-right: 1rem;
  justify-content: space-between;
  scroll-snap-align: start !important;
}

.ScrollParent {
  width: calc(100% + 1rem) !important;
  margin-left: -1rem;
  scroll-snap-type: y mandatory !important;
}

.ScrollParentDisable {
  width: calc(100% + 1rem) !important;
  margin-left: -1rem;
}

.FilterContainer {
  padding-top: 1rem;
  font-size: 0.875rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.ListContainer {
  padding-top: 0.25rem;
  height: 100% !important;
  border: 0px solid #ebebeb;
  border-right-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: -1px;
  margin-right: -1px;
  padding-left: 16px;
  padding-right: 16px;
}

.logsBorder {
  margin-left: 1rem;
  margin-right: 1rem;
  border: 0px solid #ebebeb;
  border-right-width: 1px;
  border-top-width: 1px;
  border-left-width: 1px;
  height: 100% !important;
  border-top-right-radius: 5px;
}

.logsWrapper {
  height: calc(100% - 7rem);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.TopButtonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
}

.Button {
  padding-top: 0.75rem;
}

.DividerHorizontal {
  width: 150%;
  padding-bottom: 1rem;
}

.DividerVertical {
  height: 96%;
  align-self: center;
}

.ResetButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.DeleteIcon {
  width: 0.75rem;
  height: 0.75rem;
}

.infoIcon {
  align-self: center;
  margin-left: 0.5rem;
  width: 1rem !important;
}

.ToolTipWrapper {
  display: flex;
  padding: 1rem;
  flex-direction: column;
}

.ToolTipContainer {
  display: flex;
  padding-top: 0.5rem;
}

.ToolTipContainerFirst {
  display: flex;
}

.LeftToolTipDetails {
  display: flex;
  width: 9rem;
}

.RightToolTipDetails {
  display: flex;
}

.ToolTipTitle {
  font-size: 0.875rem;
  font-weight: 600;
}

.ToolTipDetail {
  font-size: 0.875rem;
  font-weight: 400;
}

.DateSkeleton {
  margin-top: 0.3rem;
  padding-right: 1rem;
}
.InactiveButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}
.LogsContainer {
  height: 100%;
}
.RefreshIconTextContainer {
  display: flex;
  justify-content: center;
}
.RefreshTextContainer {
  align-self: center;
}
.RefreshIcon {
  align-self: center;
  cursor: pointer;
  display: flex;
}
.RefreshText {
  align-self: flex-start;
  text-align: right;
  font-size: 0.75rem;
  margin-right: 0.5rem;
  font-weight: 600;
}
.RefreshTextDate {
  text-align: center;
  font-size: 0.75rem;
  margin-right: 0.5rem;
  margin-top: 0;
}
.RefreshTextSkeleton {
  margin-right: 0.5rem;
  width: 5rem;
}
.RefreshTextDateSkeleton {
  margin-right: 0.5rem;
  width: 8rem;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.refreshStart {
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-play-state: running;
}

.iconContainer {
  display: flex;
  height: 2rem;
  width: 2rem;
  margin-bottom: 1rem;
  cursor: pointer;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.iconContainerSkeleton {
  margin-bottom: 1rem;
  padding: 3px;
}

.iconContainerDead {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  margin-bottom: 1.5rem;
}

.loadingContainer {
  height: 2.25rem;
  margin-bottom: 1.5rem;
}

.ToggleContainer {
  margin-right: 1rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  align-self: center;
}
.LoadingUidContainer {
  display: flex;
  justify-content: space-between;
}
.UidContainer {
  display: flex;
  justify-content: space-between;
}

.ToggleLoadingContainer {
  position: relative;
  align-self: center;
}
.ToggleSkeletonContainer {
  margin-right: 1rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  align-self: center;
}
.ChargePointNameWrapper {
  display: flex;
  flex-direction: row;
}
.ConnectorsWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  flex-basis: 0;
}

.ChargePointSpecsWrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 0;
}

.ChargePointSpecsContainer {
  display: flex;
  justify-content: center;
  flex-grow: 1.2;
  flex-basis: 0;
}
.SpecsEdit {
  padding-left: 1rem;
}

.PrivacyToggleContainer {
  margin-left: 0.5rem;
}
.RefreshIconTextSkeleton {
  display: flex;
  justify-content: end;
}
.RefreshIconSkeleton {
  margin-right: 4px;
  align-self: center;
}
.LiveLogs {
  display: flex;
  justify-content: flex-end;
}
.RefreshSvg {
  margin-right: 4px;
}
.LiveLogsToggleSkeleton {
  display: flex;
  justify-content: center;
}
.LiveLogsToggle {
  display: flex;
  align-self: center;
  margin-left: 8px;
  margin-right: 8px;
  align-items: center;
}

@media screen and (max-width: 1130px) {
  .ChargePointSpecsWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
  }

  .ChargePointSpecsContainer {
    display: flex;
    justify-content: center;
    flex-grow: 1.2;
    flex-basis: 0;
    padding-left: 1rem;
  }
}

.ChartSkeletonContainer {
  margin-top: 1rem;
}

.ChartResetButtonSkeleton {
  display: flex;
  justify-content: flex-end;
  right: 33px;
}
