.ChartResetButton {
  display: flex;
  justify-content: flex-end;
  right: 33px;
}

.WrapperContainer {
  margin-top: 1rem;
}

.StatisticsSubTitle {
  font-weight: 600;
  align-self: center;
  color: black;
}

.MapHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.OverlayText {
  font-weight: 600;
  font-size: 1rem;
  opacity: 100%;
  color: rgb(255, 0, 0);
  position: absolute;
  top: 40%;
  left: 40%;
}

.OverlayTest {
  filter: blur(5px);
}

.GraphContainer {
  position: relative;
}
