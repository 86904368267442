.label {
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.input {
  width: 100%;
  text-align: start;
  height: 2.625rem;
  text-indent: 0.625rem;
  background-color: transparent;
}
.inputContainer {
  width: 17.5rem;
  margin-left: auto;
  margin-right: auto;
}

.CheckPickerContainer {
  margin-top: 1.5rem;
  width: 17.5rem;
  margin-left: auto;
  margin-right: auto;
  flex-grow: 1;
  width: 100%;
}

.Submit {
  margin-right: auto;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-top: 1.5rem;
}

.ErrorStyling {
  height: 0.6em;
  font-size: 0.75rem;
}

.CheckPickerPlaceholder {
  font-size: 14px;
}
