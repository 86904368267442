.item {
  padding-top: 0px;
  padding-bottom: 0px;
}

.ListContainer {
  height: 100%;
}
.AddButton {
  margin-top: 0.5rem;
  height: 32px;
  width: 100%;
  border: 1px dashed;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  background-color: transparent;
}

.IconContainer {
  display: flex;
  align-self: center;
}
.ButtonContainer {
  display: flex;
  justify-content: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
