.Container {
  display: block;
  flex-direction: column;
  margin-top: 2rem;
}

.Button {
  float: right;
}

.Label {
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.SelectPickerContainer {
  width: 14rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.JsonContainer {
  width: 100%;
  height: 8rem;
  box-shadow: 0px 0px 4px;
  margin-bottom: 2rem;
}
.ResponseIn {
  max-height: 500px;
  transition: max-height 0.3s ease-in;
}
.ResponseOut {
  max-height: 0;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
}
.TextArea {
  height: 12.5rem;
  width: 100%;
  text-align: start;
  padding: 0.5rem;
}
.ResponseTitle {
  margin-top: 1rem;
}
