.EmptySet {
  margin-top: 1.25rem;
  text-align: center;
}

.Button {
  font-size: 0.75rem;
}

.ModalTitle {
  font-weight: bold;
}

.Container {
  height: 100%;
  overflow: hidden;
}
