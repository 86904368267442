.Container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.FileNameContainer {
  display: flex;
  flex-grow: 6;
  flex-basis: 0;
  min-width: 0;
}

.FileNameText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0.5rem;
}

.FileNameText:hover {
  cursor: pointer;
  text-decoration: underline;
}

.TextUploading {
  font-style: italic;
}

.CreatedAtContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: flex-end;
}

.FileContainer {
  display: flex;
  margin-right: 0.5rem;
  align-items: center;
}

.spinner {
  width: 1.25rem;
  text-align: center;
  display: flex;
  justify-content: center;
}

.spinner > div {
  width: 0.25rem;
  height: 0.25rem;

  border-radius: 50%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
