.Wrapper {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}
.Container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
}
.AccordionLeftText {
  font-size: 0.875rem;
  padding: 0.313rem;
  padding-left: 0rem;
  font-weight: 600;
}
.AccordionRightText {
  font-size: 0.875rem;
  align-self: center;
}

.IFTextInput {
  align-self: center !important;
  height: 1.875rem;
  width: 3rem;
}
.FieldContainer {
  display: flex;
}
.Ampere {
  font-size: 0.875rem;
  padding: 0.313rem;
  margin: auto;
  font-weight: 600;
  margin-left: 4px;
}
.Icon {
  align-self: center;
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
}
.IconContainer {
  width: 1.125rem;
}

.ReadOnlyValueContainer {
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
}
