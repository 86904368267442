.input {
  box-shadow: 0px 0px 4px #000000;
  border-radius: 5px;
  border-width: 1.5px;
  border-style: solid;
  font-family: 'ProximaNova';
  text-align: center;
  position: relative;
}
.error {
  box-shadow: 0px 0px 4px #000000;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  font-family: 'ProximaNova';
}
input:focus {
  outline: none;
  border-width: 1.5px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-self: center;
}
.errorContainer {
  position: relative;
  width: 100%;
  height: 0;
}
.errorText {
  position: absolute;
  font-size: 12px;
}
