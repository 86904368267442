.searchField {
  width: 25rem;
}
.searchFieldSkeleton {
  width: 25.875rem;
  min-height: 2.5rem;
}
.configurationList {
  height: 31.25rem !important;
}
.refreshIcon {
  align-self: center;
  cursor: pointer;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.refreshStart {
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-play-state: running;
}
.refreshTextContainer {
  align-self: center;
}

.refreshText {
  text-align: right;
  font-size: 0.75rem;
  font-weight: 600;
  align-self: flex-end;
}
.refreshTextDate {
  text-align: center;
  font-size: 0.75rem;
  margin-top: 0;
}
.wrapper {
  display: flex;
  margin-bottom: 1rem;
}
.refreshContainer {
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: right;
  flex: 1;
  flex-grow: 1;
}
.refreshTextSkeleton {
  height: 0.5rem;
  width: 5rem;
  margin-right: 0;
  margin-left: auto;
}
.refreshTextDateSkeleton {
  height: 0.5rem;
  width: 8rem;
}
.refreshIconSkeleton {
  height: 1.5rem !important;
  width: 1.5rem;
}
.emptyState {
  height: 31.25rem;
}
