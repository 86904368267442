.topContainer {
  display: flex;
  flex-grow: 1;
  margin-bottom: 1.5rem;
  justify-content: space-between;
  margin-right: 2.5rem;
}
.bottomContainer {
  display: flex;
  flex-grow: 1;
  height: 22rem;
}
.bottomWrapper {
  display: flex;
  flex-direction: row;
}
.actionsContainer {
  margin-left: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.iconsDivider {
  display: flex;
  height: 1px;
  margin-top: 1rem;
}
.topItem {
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
}
.topLeftContainer {
  display: flex;
  height: 100%;
}
.title {
  font-size: 1.25rem !important;
  font-weight: 600;
  line-height: 4px !important;
  padding-top: 4px;
  padding-bottom: 4px;
}

.bottomItem {
  flex-grow: 2;
  flex-basis: 0;
  align-self: center;
  margin-left: 4px;
}

.tooltipIcons {
  align-self: center;
  margin-top: 1rem;
}
.textTitle {
  /* margin-top: 1rem; */
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.textInfo {
  font-weight: normal;
  font-size: 1rem;
  padding: 0px;
}
.buttonsContainer {
  display: flex;
  flex-basis: 0;
  margin-left: 1rem;
  /* margin-bottom: 0.5rem; */
  justify-content: flex-end;
  flex-direction: column;
}

.box {
  width: 12px;
  height: 12px;
  align-self: center;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  border-radius: 2px;
}

.number {
  align-self: center;
  margin-left: 0.25rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1rem;
}
.icon {
  width: 1.5rem;
  height: 1.5rem;
  align-self: center;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  border-radius: 2px;
}

.textInput {
  font-size: 1rem !important;
  padding-top: 4px;
  padding-bottom: 4px;
  min-width: 14.5rem;
  max-width: 21rem;
  text-align: left !important;
  height: 2rem;
  margin-bottom: 1rem;
  padding-left: 8px;
}

.buttonIcon {
  width: 1rem !important;
  height: 1rem;
}

.DeleteContainer {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
.cityInput {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.addCityButton {
  margin-left: 0.5rem;
  margin-top: 0.25rem;
  cursor: pointer;
}

.selectPicker {
  border-radius: 5px;
  width: 14.5rem;
}

.addCityIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}
