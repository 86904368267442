.Container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.AddContainer {
  cursor: pointer;
  padding-right: 0.875rem;
  padding-left: 0.875rem;
}
