.backgroundImage {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
}

.loginwrapper {
  border-radius: 5px;
  position: absolute;
  width: 25rem;
  height: 37.5rem;
  margin: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
}
.logo {
  margin-right: auto;
  margin-left: auto;
  margin-top: 3.5rem;
  margin-bottom: 2.5rem;
}
.img {
  width: 15.625rem;
}
.titleContainer {
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 1.25rem;
}
.title {
  font-size: 1.563rem;
}

.label {
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.input {
  width: 100%;
  text-align: start;
  height: 2.5rem;
  text-indent: 0.625rem;
  background-color: transparent;
}
.inputContainer {
  margin-top: 1rem;
  width: 17.5rem;
  margin-left: auto;
  margin-right: auto;
}
.signIn {
  margin-right: auto;
  margin-left: auto;
  margin-top: 2.5rem;
}
.linkContainer {
  margin-right: auto;
  margin-left: auto;
  margin-top: 6rem;
}
.link {
  font-size: 0.75rem;
}
