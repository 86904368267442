.MaxPowerContainer {
  display: flex;
  flex-direction: column;
  width: 5rem;
  justify-content: center;
}

.ChargingLogoContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 0.25rem;
}

.FlashContainer {
  width: 16px;
  height: 16px;
}

.MaxPowerNumber {
  font-size: 0.875rem;
  font-weight: 700;
}

.MaxPowerText {
  font-size: 0.875rem;
  align-self: center;
  font-weight: 400;
  margin-top: 0px;
}

.TextContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ChademoStyle {
  width: 24px;
}

.Type2Style {
  width: 24px;
}

.ComboCCSStyle {
  width: 1.5rem;
}

.SchukoStyle {
  width: 24px;
}

.IconContainer {
  display: flex;
  padding-left: 1rem;
  justify-content: flex-start;
  align-items: center;
}

.iconContainer {
  transition: opacity 0.5s;
  opacity: 1;
  border-radius: 5px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-left: 0.3rem;
}

.UIDContainerEdit {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
  font-size: 0.875rem;
  font-weight: 400;
  padding-left: 1rem;
}

.UIDContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.875rem;
  font-weight: 400;
  padding-left: 1rem;
}

.MiddleContentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 0;
}

.MiddleContentContainerEdit {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: flex-end;
  padding-right: 1rem;
}

.StatusContainer {
  display: flex;
  width: 7.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-radius: 1em;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.StatusText {
  font-size: 0.875rem;
  font-weight: 500;
}

.ActionsTextContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0.3rem;
  font-size: 0.875rem;
  font-weight: 600;
}

.RightDetailsWrapper {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: flex-end;
}

.ClockIcon {
  margin-right: 0.25rem;
}

.ArrowContainer {
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.ButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.Icon {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  height: 2.5rem;
  cursor: pointer;
}

.SelectPickerContainer {
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  padding-left: 1rem;
  align-items: center;
}

.TextInputStyle {
  width: 2.5rem;
  margin-right: 0.25rem;
  font-weight: bold;
  font-size: 0.875rem !important;
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important;
}

.OngoingSessionContainer {
  display: flex;
  width: 100%;
}

.visible {
  position: absolute;
  transition: opacity 0.5s;
  opacity: 1;
  width: 0.9rem !important;
}

.hide {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.UidCopyContainer {
  width: 1.5rem;
  height: 1.5rem;
}

.StatusContainerEdit {
  display: flex;
  width: 7.5rem;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
}

.ContentContainer {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: center;
}

.LeftDetailsContainer {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 0;
}

.StatusContainerWrapper {
  display: flex;
  flex-direction: row;
}

.LastUpdatedContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 0.5rem;
  padding-left: 1.35rem;
  border-radius: 0px;
  border-bottom-right-radius: 1em;
  border-top-right-radius: 1em;
  margin-left: -16px;
  width: 5rem;
}

.MiddleContentContainerNoPermissions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  flex-basis: 0;
  padding-right: 1rem;
}
.InfoIconConatiner {
  display: flex;
  align-items: center;
}
.InfoIcon {
  margin-left: 0.5rem;
  width: 1rem !important;
}

.ToolTipWrapper {
  display: flex;
  padding: 1rem;
  flex-direction: column;
}

.ToolTipContainer {
  display: flex;
  padding-top: 0.5rem;
}

.ToolTipContainerFirst {
  display: flex;
}

.LeftToolTipDetails {
  display: flex;
  width: 9rem;
}

.RightToolTipDetails {
  display: flex;
}

.ToolTipTitle {
  font-size: 0.875rem;
  font-weight: 600;
}

.ToolTipDetail {
  font-size: 0.875rem;
  font-weight: 400;
}
