.Container {
  display: flex;
  flex: 1;
}

.ChargingScheduleChartContainer {
  width: 100%;
}

.ChargingSchedulePeriodListContainer {
  width: 100%;
}
