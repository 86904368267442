.container {
  height: 3rem;
  display: flex;
  justify-content: center;
}

.row {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  justify-content: center;
  min-width: 0;
}
.leftText {
  margin-left: 1rem;
  align-self: center;
  font-size: 0.875rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.middleText {
  text-align: center;
  align-self: center;
  font-size: 0.875rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0.3125rem;
}
.editIcon {
  margin-right: 1rem;
  align-self: center;
  margin-left: auto;
  cursor: pointer;
  opacity: 0.7;
}
.infoIcon {
  color: gray;
  opacity: 0.7;
  align-self: center;
  margin-left: 0.5rem;
  width: 1rem !important;
}
.textIcon {
  display: flex;
  flex-grow: 1;
  min-width: 0;
}
.buttonsContainer {
  display: flex;
  margin-left: auto;
}
.button {
  margin-right: 1rem;
  align-self: center;
  margin-left: auto;
}
.input {
  height: 2rem;
  align-self: center;
  font-size: 0.875rem;
  max-width: 15rem;
}
.tooltipType {
  display: flex;
  justify-content: center;
}
.tooltipText {
  flex-grow: 1;
  align-self: center;
  text-align: left;
  padding: 5px;
  margin: auto;
  font-size: 0.875rem;
}
.buttonIcon {
  width: 1rem !important;
  height: 1rem !important;
}
