.CardWrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  cursor: default;
}

.ButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 1rem;
}

.StatusWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 0;
}

.StatusContainer {
  display: flex;
  width: 6rem;
  height: 1.5rem;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
}

.StatusText {
  font-size: 0.875rem;
  font-weight: 500;
}

.ButtonIcon {
  width: 1rem !important;
  height: 1rem;
}

.Button {
  margin-left: 1rem;
}

.VisualNumberContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  min-width: 0;
}

.ReferenceContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  min-width: 0;
}

.UIDContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  min-width: 0;
}

.TypeContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 400;
}

.PenIconContainer {
  display: flex;
  flex-grow: 0.7;
  flex-basis: 0;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
}

.PenIcon {
  cursor: pointer;
}

.DeleteIconContainer {
  display: flex;
  flex-grow: 0.7;
  flex-basis: 0;
  justify-content: center;
  font-size: 0.875rem;
  align-items: center;
}

.DeleteIcon {
  cursor: pointer;
}

.SelectPicker {
  width: 9rem;
}

.VisualNumberCopyContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 0;
  flex-grow: 1;
  flex-basis: 0;
}
.wrapText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.iconContainer {
  transition: opacity 0.5s;
  opacity: 1;
  border-radius: 5px;
  cursor: pointer;
  width: 0;
  height: 0;
}

.visible {
  position: absolute;
  transition: opacity 0.5s;
  opacity: 1;
  width: 1.1rem !important;
}

.hide {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.IFTextContainer {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.IFText {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: 2rem;
}
