body {
  margin: 0;
  font-family: 'ProximaNova' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.rs-toast-container-top-center,
.rs-toast-container-top-start,
.rs-toast-container-top-end {
  z-index: 10000 !important;
}

.rs-btn-close {
  color: white;
}

.rs-picker-toggle.rs-btn-lg {
  font-size: 14px !important;
}

.rs-picker-subtle .rs-picker-toggle {
  z-index: 0 !important;
}
.rs-picker-menu {
  z-index: 1500 !important;
}
.rs-input {
  box-shadow: 0px 0px 4px #000000;
}
.rs-picker-select-menu-item.rs-picker-select-menu-item-active {
  font-family: 'ProximaNova';
  font-size: 14px;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  font-family: 'ProximaNova';
  font-size: 14px;
}
.rs-picker-default {
  box-shadow: 0px 0px 4px #000000;
  border-radius: 6px;
}
