.TabTitle {
  font-size: 0.875rem;
  text-transform: none;
  margin-bottom: 0.125rem;
  width: 100%;
  font-weight: 600;
}

.AccessGroupsTabTitle {
  font-size: 0.875rem;
  text-transform: none;
  margin-bottom: 0.125rem;
  font-weight: 600;
}

.ChargePointSpecsTabsWrapper {
  margin-top: -11px;
}

.SpecsTab {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  width: 100%;
  height: 100%;
}

.ChargePointSpecsWrapper {
  width: 100%;
}

.CautionContainer {
  display: flex;
  margin-right: 0.5rem;
  align-items: center;
  margin-bottom: 0.25rem;
}

.CautionTextContainer {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.CautionTextWrapper {
  position: relative;
}

.CautionText {
  font-size: 12px;
  font-weight: 500;
  position: relative;
  z-index: 1;
}
.ListContainer {
  height: 224px;
}
