.Container {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding-right: 0.5rem;
  padding-left: 0rem;
  min-height: 5rem;
  height: 80px;
  align-items: center;
  align-content: center;
}

.StationDetailsContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 15;
  flex-basis: 0;
  justify-content: space-between;
}

.LastUpdatedContainer {
  display: flex;
  justify-content: flex-end;
  font-size: 0.75rem;
}

.StationNameContainer {
  display: flex;
  font-size: 0.875rem;
  padding-top: 0.5rem;
  padding-right: 1rem;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.StatusBox {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  margin-left: 0.25rem;
}

.ConnectorsWrapper {
  display: flex;
  flex-direction: row;
}

.ConnectorContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-right: 0.5rem;
}

.ChargersWrapper {
  display: flex;
  flex-direction: row;
}

.BottomContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 20px;
}

.ChargersContainer {
  display: flex;
  flex-direction: row;
  padding-left: 0.5rem;
}

.IconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  margin-right: 0.625rem;
  position: relative;
}

.ImgBox {
  width: auto;
  height: 19px;
  margin-left: 0.25rem;
}

.ChargersText {
  font-size: 0.75rem;
  vertical-align: center;
  margin-top: 2px;
}

.IconFilled {
  opacity: 0;
  position: absolute;
  transition: opacity 0.2s;
}

.IconOutlined {
  opacity: 1;
  z-index: 1;
  transition: opacity 0.2s;
}

.IconOutlined:hover {
  opacity: 0;
}

.IconOutlined:hover ~ .IconFilled {
  opacity: 1;
}
.New {
  font-weight: 600;
  align-self: flex-end;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.NewContainer {
  border-radius: 4px;
  margin-right: 0.5rem;
  height: min-content;
}

.StationNameDateContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 44px;
}

.StationNameText {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
