.Container {
  display: block;

  flex-direction: column;
}

.StationsContainer {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: block;
  overflow: hidden;
  height: 60vh;
}

.Button {
  float: right;
}
