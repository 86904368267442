.Container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.LeftContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  margin: 1rem;
}

.RightContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 1rem;
}

.RightContainerEdit {
  display: flex;
  height: 100%;
  flex-grow: 0;
  flex-basis: 0;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
}

.DateContainer {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
}

.EditButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.IconContainer {
  display: flex;
  height: 2rem;
  width: 2rem;
  margin-bottom: 1rem;
  cursor: pointer;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.IconContainerSkeleton {
  margin-bottom: 1rem;
  padding: 3px;
}

.CardDetailsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.NameText {
  font-size: 18px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
}

.CardGroupsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding-left: 4px;
  padding-right: 4px;
}

.CardGroupsContainerEdit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  padding-left: 4px;
  padding-right: 4px;
}

.CardsGroupsText {
  font-size: 14px;
  font-weight: 600;
}

.TransactionsLogText {
  font-size: 14px;
  font-weight: 600;
}

.SubscriptionsContainer {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  flex-grow: 1;
  height: 100%;
  flex-basis: 0;
}

.AccessGroupsContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  flex-basis: 0;
}

.TransactionsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  flex-basis: 0;
  overflow: hidden;
}

.FilterContainer {
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
  margin-right: 2px;
  margin-left: 2px;
}

.RefreshIconTextContainer {
  display: flex;
  justify-content: center;
}
.RefreshTextContainer {
  align-self: center;
}
.RefreshIcon {
  align-self: center;
  cursor: pointer;
}
.RefreshText {
  align-self: flex-start;
  text-align: right;
  font-size: 0.75rem;
  margin-right: 0.5rem;
  font-weight: 600;
}
.RefreshTextDate {
  text-align: center;
  font-size: 0.75rem;
  margin-right: 0.5rem;
  margin-top: 0;
}
.RefreshTextSkeleton {
  margin-right: 0.5rem;
  margin-left: auto;
  width: 5rem;
}

.RefreshTextDateSkeleton {
  margin-right: 0.5rem;
  width: 8rem;
}

.TransactionsTitlesContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.refreshStart {
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-play-state: running;
}

.TransactionLogContainer {
  display: flex;
  align-items: center;
}

.IFTextReferenceEdit {
  font-size: 18px;
  font-weight: 600;
  height: 24px;
}

.CardInfoSkeletonContainer {
  display: flex;
  align-items: center;
}
.CardInfoSkeleton {
  margin-left: 1rem;
}

.ReferenceContainer {
  display: flex;
  align-items: center;
}
.InfoIconContainer {
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
}

.Icon {
  align-self: center;
}
.InfoTextContainer {
  align-self: center;
  margin-left: 0.5rem;
}
.InfoText {
  margin-left: 0.5rem;
}
.TypeContainer {
  align-self: center;
}

.SelectPickerValueContainer {
  display: flex;
}
.SelectPickerText {
  font-size: 14px;
  margin-left: 1rem;
}
.RenderMenuItemContainer {
  display: flex;
  align-content: space-between;
  width: 6.25rem;
}
.TypeSelectPicker {
  margin-left: -0.5rem;
}
.TypeText {
  margin-left: 1rem;
}
