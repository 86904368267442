.Container {
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  height: 48px;
}

.MaxPowerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 5rem;
  border-radius: 5px 0px 0px 5px;
  padding-bottom: 0.25rem;
}

.IconContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1rem;
}

.UIDContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.875rem;
  font-weight: 400;
  padding-left: 1rem;
}

.StatusContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.ActionsContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: flex-end;
  align-items: center;
}

.LeftDetailsContainer {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 0;
}

.MiddleContentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 0;
}

.MiddleContentContainerNoPermissions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  flex-basis: 0;
  padding-right: 1rem;
}

.StatusContainerWrapper {
  display: flex;
  flex-direction: row;
}

.StatusContainer {
  display: flex;
  width: 7.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-radius: 1em;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.LastUpdatedContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 0.5rem;
  padding-left: 1.35rem;
  border-radius: 0px;
  border-bottom-right-radius: 1em;
  border-top-right-radius: 1em;
  margin-left: -16px;
  width: 5rem;
}

.RightDetailsSkeleton {
  padding-right: 1rem;
}
