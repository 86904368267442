.wrapper {
  display: flex;
  justify-content: center;
}
.downloadIcon {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.loadingVisible {
  cursor: pointer;
  align-self: center !important;
  margin-right: -1.573rem;
  position: relative;
  transition: opacity 0.5s;
  opacity: 1;
  align-self: center;
}
.loadingHide {
  cursor: pointer;
  align-self: center !important;
  margin-right: -1.573rem;
  position: relative;
  transition: opacity 0.5s;
  opacity: 0;
  pointer-events: none;
  align-self: center;
}
.pdfIcon:hover {
  opacity: 0.9;
}
.visible {
  cursor: pointer;
  position: relative;
  transition: opacity 0.5s;
  opacity: 1;
  align-self: center;
}
.hide {
  position: relative;
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
  align-self: center;
}
