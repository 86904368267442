.TitleIconsContainer {
  display: flex;
  justify-content: space-between;
}
.TitleContainer {
  display: flex;
  flex-grow: 2.5;
  flex-basis: 0;
  min-width: 0;
}

.IconsContainer {
  display: flex;
  flex-grow: 3;
  flex-basis: 0;
  justify-content: space-between;
  align-self: center;
}

.PriceTitle {
  font-size: 14px;
  font-weight: 600;
}

.ChargingIconsContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: center;
}
.FlashIcon {
  width: 14px;
  height: 14px;
}

.SubscriptionsContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
