.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  padding: 1rem;
}
.filterContainer {
  margin-bottom: 1rem;
  flex-basis: 0;
}
.filter {
  border-radius: 5px;
  overflow: hidden;
}

.stationsChargersContainer {
  display: flex;
  flex-grow: 1;
}

.stationList {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
}

.chargePointsWrapper {
  flex-grow: 4.1;
  flex-basis: 0;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
}

.chargePointsContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
