.container {
  display: flex;
  flex-direction: row;
  flex: 1 1 0px;
  justify-content: space-between;
  padding-right: 48px;
  height: 64px;
}
.accordion {
  border-radius: 5px;
  min-height: 3.5rem;
}

.accordionStationWrapper {
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;
  padding-left: 1rem;
  padding-top: 1rem;
}

.accordionTextPower {
  display: flex;
  flex-direction: column;
  flex-grow: 0.5;
  flex-basis: 0;
  align-items: center;
  padding-top: 1.05rem;
}

.accordionDuration {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  flex-grow: 0.8;
  flex-basis: 0;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
}

.uidContainer {
  padding-left: 0.3rem;
}

.accordionDateContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;
  padding-top: 1rem;
}

.accordionTime {
  font-weight: 400;
  font-size: 16px;
}

.accordionDate {
  margin: 0;
  margin-top: 0.375rem;
  font-weight: 400;
  font-size: 8px;
  padding-bottom: 0.5rem;
  width: 8rem;
}

.time {
  width: 4rem;
  font-size: 12px;
}

.accordionicon {
  align-self: center;
  width: 24px;
}

.accordionIconMoney {
  align-self: center;
}

.flashIcon {
  margin-left: 1rem;
  align-self: center;
  width: 16px;
  height: 16px;
  padding-bottom: 0.46875rem;
}

.ChargingIcon {
  width: 24px;
  height: 24px;
  margin-left: 0.25rem;
  margin-right: 0.5rem;
}

.accessTimeIcon {
  margin-top: 0.05rem;
}
.accordionSummary {
  padding-left: 0 !important;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  height: 4rem;
}
.accordionStartDate {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.accordionEndDate {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.startDateContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
}

.endDateContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
}

.accordionMiddleText {
  font-size: 16px;
  font-weight: 400;
  padding-left: 0.7rem;
  margin-left: 0;
}

.accordionDurationText {
  font-size: 8px;
  font-weight: 400;
  margin-left: 0;
  margin-top: 0.375rem;
  padding-bottom: 0.5rem;
  align-items: center;
  width: 6rem;
}

.accordionTextEnergyConsumption {
  font-size: 8px;
  font-weight: 400;
  margin-left: 0;
  padding-bottom: 0.5rem;
  margin-top: 0.375rem;
  width: 6rem;
}

.accordionRightText {
  font-size: 0.875rem;
  text-align: right;
  padding: 0.313rem;
  line-height: 1rem;
  vertical-align: middle;
  margin: auto;
  margin-left: 0;
  margin-right: 0;
  padding-right: 0.625rem;
}

.leftDetailsContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  /* flex-grow: 2; */
}
.expandedLeftText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.semiBold {
  font-size: 0.875rem;
  padding: 0.313rem;
  line-height: 1rem;
  margin: auto;
  padding-left: 0.7rem;
  margin-left: 0;
  flex-grow: 1;
  flex-basis: 0;
  font-weight: 600;
}

.startText {
  font-size: 16px;
  font-weight: 600;
  padding-right: 2rem;
}

.endText {
  font-size: 16px;
  font-weight: 600;
  padding-right: 2.375rem;
}

.durationText {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding-right: 6.125rem;
}

.durationTime {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.durationContainer {
  display: flex;
  flex-direction: row;
}

.expandedRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  float: right;
  padding-right: 1.5rem;
}

.rightDetailsContainer {
  display: flex;
  flex-direction: column;
}

.stationText {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
}

.stationName {
  padding-top: 1rem;
  margin-bottom: -0.2rem;
  width: 8rem;
  font-size: 4px;
}

.chargePointName {
  font-size: 8px;
  padding-bottom: 0.5rem;
  width: 7.5rem;
}

.amountContainer {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  flex-grow: 0.5;
  flex-basis: 0;
  align-items: center;
}

.currencyText {
  font-size: 16px;
  font-weight: 400;
}

.amount {
  font-size: 8px;
  font-weight: 400;
  margin: 0;
  margin-top: 0.375rem;
  padding-bottom: 0.5rem;
  width: 6rem;
}

.accordionDetails {
  padding: 16px 16px 20px 16px;
}

.middleDetailsContainer {
  display: flex;
  flex-direction: column;
}

.connectorTypeContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
}

.connectorIDContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
}

.connectorTypeText {
  font-size: 16px;
  font-weight: 600;
  padding-right: 2rem;
}

.connectorIDText {
  font-size: 16px;
  font-weight: 600;
  padding-right: 3.625rem;
}

.connectorID {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.reservationContainer {
  display: flex;
  flex-direction: row;
}

.reservationText {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding-right: 3.125rem;
}

.reservationID {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.rightDetailsContainer {
  display: flex;
  flex-direction: column;
}

.subscriptionContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
  justify-content: space-between;
}

.subscriptionText {
  font-size: 16px;
  font-weight: 600;
  padding-right: 2rem;
}

.subscription {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.chargingLevelContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
  justify-content: space-between;
}

.chargingLevelText {
  font-size: 16px;
  font-weight: 600;
}

.tariffContainer {
  display: flex;
  flex-direction: row;
}

.tariffText {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding-right: 6.125rem;
}

.tariff {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.chargingLevelFlashContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.chargingLevelFlashIcon {
  align-self: center;
  width: 16px;
  height: 16px;
}

.icon {
  width: 1.2rem !important;
  height: 1.2rem !important ;
}

.stationIcon {
  width: 1.8rem !important;
  height: 1.8rem !important ;
}
