.WrapperContainerVisible {
  display: flex;
  flex-direction: row;
  height: 48px;
  border-radius: 5px;
  width: 300px;
  transition: all 0.5s;
}

.WrapperContainerHidden {
  display: flex;
  flex-direction: row;
  height: 48px;
  border-radius: 5px;
  width: 48px;
  transition: all 0.5s;
}

.TextInput {
  height: 48px;
  border-color: white !important;
  box-shadow: none !important;
  text-align: left;
  padding-left: 0.5rem;
  width: 100%;
}

.TextInputContainerVisible {
  display: flex;
  flex-direction: row;
  width: 60%;
  opacity: 1;
  transition: all 0.25s;
}

.TextInputContainerHidden {
  display: flex;
  flex-direction: row;
  width: 0;
  opacity: 0;
  transition: all 0.25s;
}

.ResultsCounterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
}

.IconsContainerVisible {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex: 1;
  opacity: 1;
  transition: all 0.25s;
}

.IconsContainerHidden {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex: 1;
  opacity: 0;
  transition: all 0.25s;
}

.CircleContainer {
  padding: 0.1rem;
}

.CounterText {
  font-size: 14px;
}

.SearchContainer {
  display: flex;
  align-items: center;
  padding-left: 0.8125rem;
  cursor: pointer;
}

.DividerVisible {
  opacity: 1;
  transition: all 0.25s;
}

.DividerHidden {
  opacity: 0;
  transition: all 0.25s;
}
