.UploadDiagnosticsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  height: 21.5rem;
}

.ButtonContainer {
  display: flex;
  justify-content: space-between;
}

.RefreshIconTextContainer {
  display: flex;
  justify-content: flex-start;
}

.RefreshTextContainer {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-left: 0.5rem;
}

.RefreshIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.RefreshText {
  align-self: flex-start;
  text-align: right;
  font-size: 0.75rem;
  font-weight: 600;
}

.RefreshTextDate {
  text-align: center;
  font-size: 0.75rem;
  margin-top: 0;
}

.RefreshTextSkeleton {
  margin-right: 0.5rem;
  margin-left: auto;
  width: 5rem;
}

.RefreshTextDateSkeleton {
  margin-right: 0.5rem;
  width: 8rem;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.refreshStart {
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-play-state: running;
}
