.ChargePointHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.ChargePointName {
  font-weight: 600;
  margin-left: 0.75rem;
  margin-right: 8px;
  margin-bottom: 4px;
}
