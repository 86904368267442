.Container {
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  height: 1.5rem;
}

.LeftContainer {
  display: flex;
  flex-grow: 3;
  flex-basis: 0;
  min-width: 0;
}

.RightContainer {
  display: flex;
  flex-grow: 2.5;
  flex-basis: 0;
  justify-content: space-between;
}

.TextContainer {
  align-self: flex-end;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DateTextContainer {
  align-self: center;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
