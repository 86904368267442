.Container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 0.45rem;
  padding-bottom: 0.45rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.FileNameContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
}

.CreatedAtContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: flex-end;
}

.UploadIconContainer {
  display: flex;
  margin-right: 0.5rem;
  align-items: center;
}
