.container {
  height: 3rem;
  display: flex;
  justify-content: center;
}
.row {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  justify-content: center;
}

.right {
  align-self: center;
  margin-right: 1rem;
}
.middle {
  width: 6rem;
  height: 1.5rem;
  align-self: center;
  margin-left: 2.5rem;
}
.left {
  margin-left: 1rem;
  align-self: center;
  margin-right: auto;
  width: 18rem;
  height: 1.5rem;
}
