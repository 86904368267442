.Container {
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  height: 64px;
  align-items: center;
  padding: 0.5rem;
}

.NameContainer {
  display: flex;
  flex: 2;
}

.MaxCurrentContainer {
  display: flex;
  flex: 1;
}

.ArrowContainer {
  display: flex;
  flex: 0.125;
  justify-content: flex-end;
}
