.textTitle {
  font-size: 1.25rem;
  font-weight: 500;
}

.inputContainer {
  margin-bottom: 1.5rem;
}
.lgInput {
  margin-top: 0.5rem;
  min-width: 14.5rem;
  max-width: 23rem;
  text-align: left;
  height: 2.25rem;
  text-indent: 0.5rem;
}
