.Connector {
  height: 48px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0.25rem;
  margin-left: 0.125rem;
}

.ConnectorText {
  font-size: 0.875rem;
  margin-left: 0.5rem;
}

.ChargerSpeedContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  align-content: center;
  align-items: center;
  width: max-content;
  height: inherit;
}

.FlashIcon {
  width: 16px;
  height: 16px;
  align-self: center;
  margin-bottom: 4px;
}

.ChargerTypeContainer {
  display: flex;
  flex-direction: row;
  margin-left: 0.75rem;
  align-items: center;
  flex: 3;
  height: inherit;
}

.ConnectorStatusText {
  flex: 2;
  text-align: center;
  margin: 0;
}

.ConnectorSpeedIconContainer {
  display: flex;
  flex-direction: row;
  padding-top: 0.375rem;
  width: 70px;
  justify-content: center;
}

.DividedText {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-content: center;
  align-items: center;
  font-size: 0.875rem;
}

.ChademoStyle {
  width: 24px;
}

.Type2Style {
  width: 24px;
}

.ComboCCSStyle {
  width: 1.5rem;
}

.SchukoStyle {
  width: 24px;
}

.KiloWattText {
  font-size: 0.875rem;
  margin-top: 0px;
}

.killowattNumberText {
  font-weight: 600;
}
