.TitleIconsContainer {
  display: flex;
  justify-content: space-between;
}

.TitleContainer {
  display: flex;
  flex-grow: 2.5;
  flex-basis: 0;
  min-width: 0;
}

.IconsContainer {
  display: flex;
  flex-grow: 3;
  flex-basis: 0;
  justify-content: space-between;
  align-self: center;
}

.RuleTitle {
  font-size: 0.875rem;
  font-weight: 600;
}

.ScheduleContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
