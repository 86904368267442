.Container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.LeftContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  margin: 1rem;
}

.RightContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 1rem;
}

.RightContainerEdit {
  display: flex;
  height: 100%;
  flex-grow: 0;
  flex-basis: 0;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
}

.TitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ChargePointNameContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.NameText {
  font-size: 1.5rem;
  font-weight: 400;
}

.TextInputStyle {
  font-size: 1.5rem !important;
  font-weight: 400;
}

.DateContainer {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
}

.ChargePointUidContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.ChargingIcon {
  width: 20px;
  height: 20px;
  margin-left: 0.25rem;
  margin-right: 0.5rem;
}

.UIDText {
  margin-top: 2px;
  font-size: 0.875rem;
  font-weight: 400;
}

.ConnectorsTextContainer {
  padding-top: 0.5rem;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
  padding-right: 1rem;
  padding-left: 1rem;
  height: 28px;
}

.ConnectorsContainer {
  display: flex;
  flex-grow: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  flex-basis: 0;
}

.TabsContainer {
  display: flex;
  padding-left: 1rem;
  margin-bottom: -1.5px;
  padding-right: 1rem;
  justify-content: space-between;
  scroll-snap-align: start !important;
}

.Scroll {
  width: calc(100% + 1rem) !important;
  margin-left: -1rem;
  scroll-snap-type: y mandatory !important;
}

.ScrollParent {
  width: calc(100% + 1rem) !important;
  margin-left: -1rem;
  scroll-snap-type: y mandatory !important;
}

.ListContainer {
  padding-top: 0.25rem;
  height: 100% !important;
  border: 0px solid #ebebeb;
  border-right-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: -1px;
  margin-right: -1px;
  padding-left: 16px;
  padding-right: 16px;
}

.EditButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.iconContainer {
  display: flex;
  height: 2rem;
  width: 2rem;
  margin-bottom: 1rem;
  cursor: pointer;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.iconContainerSkeleton {
  margin-bottom: 1rem;
  padding: 3px;
}

.UserDetailsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.UserCredentialsContainer {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
}

.NameContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  align-items: end;
}

.EmailContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
}

.EmailWrapper {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: row;
}

.NameText {
  font-size: 18px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
}

.EmailText {
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 0.2rem;
  line-height: 15px;
  align-self: center;
}

.UserGroupsWrapper {
  width: 100%;
}

.UserGroupsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  margin-right: 4px;
  margin-left: 4px;
  flex-grow: 1;
  flex-basis: 0;
}

.UserGroupsContainerEdit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  margin-right: 4px;
  margin-left: 4px;
  flex-grow: 1;
}

.UsersGroupsText {
  font-size: 14px;
  font-weight: 600;
}

.TransactionsLogText {
  font-size: 14px;
  font-weight: 600;
}

.SubscriptionsContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  height: 100%;
}

.AccessGroupsContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  flex-basis: 0;
}

.SubscriptionsContainerInvisible {
  height: 0px;
  width: 0px;
}

.AccessGroupsContainerInvisible {
  height: 0px;
  width: 0px;
}

.TransactionsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  flex-basis: 0;
  overflow: hidden;
}

.FilterContainer {
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
  padding-right: 4px;
  padding-left: 4px;
}

.RefreshIconTextContainer {
  display: flex;
  justify-content: center;
}
.RefreshTextContainer {
  align-self: center;
}
.RefreshIcon {
  align-self: center;
  cursor: pointer;
}
.RefreshText {
  align-self: flex-start;
  text-align: right;
  font-size: 0.75rem;
  margin-right: 0.5rem;
  font-weight: 600;
}
.RefreshTextDate {
  text-align: center;
  font-size: 0.75rem;
  margin-right: 0.5rem;
  margin-top: 0;
}
.RefreshTextSkeleton {
  margin-right: 0.5rem;
  margin-left: auto;
  width: 5rem;
}

.RefreshTextDateSkeleton {
  margin-right: 0.5rem;
  width: 8rem;
}

.TransactionsTitlesContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.refreshStart {
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-play-state: running;
}

.TransactionLogContainer {
  align-items: center;
}

.UserCredentialsWrapper {
  display: flex;
  flex-direction: row;
}

.UserAvatarContainer {
  position: relative;
  display: flex;
  height: min-content;
}

.StatusContainer {
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  bottom: 0.1rem;
  right: 0.1rem;
}

.StatusTextContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
}

.StatusText {
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 0.2rem;
  line-height: 15px;
}
.OngoingSessions {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 1rem;
}
.UserSessions {
  flex-grow: 2;
  flex-basis: 0;
  height: 14rem;
}
.UserSessionsEmpty {
  flex-grow: 2;
  flex-basis: 0;
  height: 5rem;
}
.UserSessionsContainer {
  flex-grow: 2;
  flex-basis: 0;
  height: 100%;
}
.SpecsSessionsContainer {
  display: flex;
  width: 100%;
}
.SpecsSessionsContainerEdit {
  display: flex;
  width: 100%;
  height: 100%;
}
.UserSpecificationsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  flex-grow: 1;
}
.SubscriptionsContainerSeparate {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  height: 100%;
  margin-right: 1rem;
}
