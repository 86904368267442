.container {
  margin: -2.05rem;
}

.innerDot {
  height: 54px;
  width: 54px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
}

.outerDot {
  height: 66px;
  width: 66px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.dot {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.clusterCount {
  justify-content: center;
  display: flex;
  flex-grow: 1;
  margin-top: 0.2rem;
  font-size: 1rem;
}

.chargingSpeedContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 0.125rem;
}

.flash {
  filter: invert(1);
  width: 20px;
  height: 20px;
  margin-left: -0.1875rem;
  margin-right: -0.1875rem;
  margin-bottom: 0.375rem;
}
