.Container {
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.LeftContainer {
  display: flex;
  flex-grow: 2.5;
  flex-basis: 0;
  min-width: 0;
  height: 1.5rem;
}

.RightContainer {
  display: flex;
  flex-grow: 3;
  flex-basis: 0;
  height: 1.5rem;
  justify-content: space-between;
}

.TextContainer {
  align-self: center;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.PriceTextContainer {
  align-self: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Name {
  width: 9rem;
  height: 1.2rem;
}
.Price {
  width: 2rem;
  height: 1.2rem;
}
