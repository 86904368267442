.ChargingSpecsAdd {
  margin-top: 0.5rem;
  width: 100%;
}

.ChargingSpecsAddPadding {
  margin-top: 0.5rem;
  width: 100%;
  padding-right: 1rem;
}

.EmptyContainer {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.ListWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 0.5rem;
}

.SubscriptionListWrapper {
  display: flex;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: column;
  overflow: hidden;
}

.SubscriptionListWrapperEditing {
  display: flex;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: column;
}
