.container {
  display: flex;
  align-self: center;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
}

.titleText {
  align-self: center;
  font-size: 1.1rem;
  font-weight: 600;
  margin-left: 0.5rem;
  padding-top: 0.1rem;
}

.text {
  align-self: center;
  font-size: 1rem;
}

.icon {
  align-self: center;
  width: 1rem !important;
}
