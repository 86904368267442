.IFTextInputStyle {
  height: 2.25rem;
  text-align: left;
  padding-left: 0.5rem;
  margin-top: 0.75rem;
}

.AddIconStyle {
  width: '18px !important';
  height: '18px !important';
}
