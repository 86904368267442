.container {
  display: flex;
  flex-direction: row;
  flex: 1 1 0px;
  flex-grow: 1;
  justify-content: center;
}
.accordion {
  min-height: 3rem;
}
.accordionicon {
  margin-left: 0.75rem;
}
.accordionSummary {
  padding-left: 0 !important;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  height: 3rem;
}
.accordionLeftText {
  font-size: 0.875rem;
  flex: 1;
  text-align: left;
  padding: 0.313rem;
  margin: auto;
  padding-left: 0.75rem;
}
.accordionRightText {
  font-size: 0.875rem;
  flex: 1;
  text-align: right;
  line-height: 1rem;
  vertical-align: middle;
  margin: auto;
}

.EmptyIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.DetailsContainer {
  width: 100%;
}

.callIdText {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1rem;
}
.codeSnippetContainer {
  position: relative;
  border-radius: 5px;
  margin-top: 1rem;
  font-family: 'ProximaNova';
}
.jsonPretty {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 1rem;
}
.iconContainer {
  display: flex;
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  cursor: pointer;
}

.icon {
  height: 16px !important;
  width: 16px !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  transition: all 0.3s !important;
}
.visible {
  opacity: 1;
  transform: scale(1);
}
.hide {
  opacity: 0;
  transform: scale(0);
  pointer-events: none;
}
