.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
  justify-content: center;
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
  justify-content: center;
}
.animateCharacter {
  text-transform: uppercase;
  background-size: auto auto;
  background-clip: border-box;
  background-size: 300% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 13s linear infinite;
  display: inline-block;
  font-size: 7rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

@keyframes textclip {
  to {
    background-position: 300% center;
  }
}
.title {
  font-size: 3rem;
  font-weight: 600;
}
.subTitle {
  font-size: 1.25rem;
}
.buttonContainer {
  margin-top: 2rem;
  align-self: center;
}
