.Container {
  display: flex;
  flex: 1;
}

.AccordionSummaryContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.NameContainer {
  display: flex;
  flex: 2;
  align-items: center;
}

.MaxCurrentContainer {
  display: flex;
  flex: 1;
  align-items: center;
}

.ChargingScheduleContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.AccordionDetailsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.ClearProfileContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.AddTopMargin {
  margin-top: -0.85rem;
}

.LabelsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  width: 100%;
  margin-top: -1rem;
}

.LabelWidth {
  width: 25%;
}

.LastLabelContainer {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.LabelMargin {
  margin-left: -1.5rem;
}

.EmptyDiv {
  width: 50px;
}

.BottomContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}
