.Container {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  height: 48px;
  margin-left: 0.125rem;
  margin-bottom: 0.25rem;
}

.MaxPowerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70px;
  border-bottom-left-radius: 9px;
  border-top-left-radius: 9px;
}

.IconContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0.75rem;
  padding-right: 0.5rem;
}

.UIDContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding-right: 3.5rem;
}

.LastUpdatedContainer {
  display: flex;
  align-items: center;
  padding-right: 1rem;
}
