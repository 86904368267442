.WrapperContainer {
  display: flex;
  flex-direction: row;
}

.GraphContainer {
  position: relative;
  width: 100%;
  cursor: auto;
}

.DayContainer {
  display: flex;
  align-items: center;
  padding-right: 1rem;
  width: 50px;
}

.dayText {
  font-size: 14px;
}

.tooltip {
  padding: 1rem;
}

.xAxisContainer {
  margin-top: -0.775rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.LabelsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
}

.LabelWidth {
  width: 25%;
}

.LastLabelContainer {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.LabelMargin {
  margin-left: -1.5rem;
}
