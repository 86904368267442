.dot {
  height: 28px;
  width: 28px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.triangle {
  width: 0;
  height: 0;
  margin-top: -4px;
  border-style: solid;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 28px;
}

.chargingSpeedContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: row;
}
