.dot {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.triangle {
  width: 0;
  height: 0;
  border: 0.5rem solid transparent;
  transform: rotate(180deg);
  margin-top: -0.25rem;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 48px;
}

.chargingSpeedContainer {
  filter: invert(1);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: row;
}

.flash {
  width: 16px;
  height: 16px;
}
