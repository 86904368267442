.Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.Skeletons {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.CompositeSchedule {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.Container {
  height: 100% !important;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
  height: 30rem;
  margin-bottom: 2rem;
}
.ItemContainer {
  overflow-x: hidden;
}

.SkeletonContainer {
  margin-bottom: 1rem;
}

.DividerContainer {
  display: flex;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.Divider {
  width: 100%;
}
.Button {
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 3rem;
}
.CompositeScheduleTitleContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.RefreshIconTextContainer {
  display: flex;
  justify-content: center;
}
.RefreshTextContainer {
  align-self: center;
}
.RefreshIcon {
  align-self: center;
  cursor: pointer;
  display: flex;
}
.RefreshText {
  align-self: flex-start;
  text-align: right;
  font-size: 0.75rem;
  margin-right: 0.5rem;
  font-weight: 600;
}
.RefreshTextDate {
  text-align: center;
  font-size: 0.75rem;
  margin-right: 0.5rem;
  margin-top: 0;
}
.RefreshTextSkeleton {
  margin-right: 0.5rem;
  width: 5rem;
}
.RefreshTextDateSkeleton {
  margin-right: 0.5rem;
  width: 8rem;
}
.RefreshIconTextSkeleton {
  display: flex;
  justify-content: end;
}
.RefreshIconSkeleton {
  margin-right: 4px;
  align-self: center;
}
.LiveLogs {
  display: flex;
  justify-content: flex-end;
}
.RefreshSvg {
  margin-right: 4px;
}
