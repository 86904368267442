.Container {
  display: flex;
  flex-direction: row;
  height: 4.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.LeftContainer {
  display: flex;
  flex-direction: column;
  flex: 3.5;
  justify-content: space-evenly;
}

.StatusContainerWrapper {
  display: flex;
  flex-direction: row;
}

.DurationContainer {
  display: flex;
  flex-direction: row;
  flex: 1.5;
  align-items: center;
  justify-content: flex-start;
}

.PercentageContainer {
  display: flex;
  flex: 1.2;
  align-items: center;
  justify-content: flex-start;
}

.ToggleContainer {
  display: flex;
  flex: 0.75;
  align-items: center;
  justify-content: flex-end;
}
