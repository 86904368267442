.Container {
  display: flex;
  flex-direction: row;
  height: 4.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.LeftContainer {
  display: flex;
  flex-direction: column;
  flex: 3.5;
  justify-content: space-evenly;
}

.NameContainer {
  font-size: 14px;
  font-weight: 500;
}

.StatusContainer {
  display: flex;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  border-radius: 1em;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.StatusContainerWrapper {
  display: flex;
  flex-direction: row;
}

.StatusText {
  font-size: 12px;
}

.DurationContainer {
  display: flex;
  flex-direction: row;
  flex: 1.5;
  align-items: center;
  justify-content: flex-start;
}

.DurationText {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.Icon {
  margin-right: 0.25rem;
}

.PercentageContainer {
  display: flex;
  flex: 1.2;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
}

.ToggleContainer {
  display: flex;
  flex: 0.75;
  align-items: center;
  justify-content: flex-end;
}

.RemoveIcon {
  cursor: pointer;
}
