.UsersContainer {
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.filterContainer {
  margin-bottom: 1rem;
  flex-basis: 0;
}
.filter {
  border-radius: 5px;
  overflow: hidden;
}
.ListDetailsContainer {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
}

.UsersList {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
}

.UserDetailsWrapper {
  flex-grow: 4.1;
  flex-basis: 0;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
}
