.container {
  display: flex;
}
.tab {
  width: 5.625rem;
  height: 3rem;
  display: flex;
}
.text {
  width: 4rem;
  margin: auto;
  align-self: center;
}
