.Container {
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
  height: 1.5rem;
}

.LeftContainer {
  display: flex;
  flex-grow: 2.5;
  flex-basis: 0;
  min-width: 0;
}

.RightContainer {
  display: flex;
  flex-grow: 3;
  flex-basis: 0;
  justify-content: space-between;
}
.IconContainer {
  margin-right: 0.25rem;
  align-self: center;
  display: flex;
  cursor: pointer;
}

.Icon {
  font-size: 16px !important;
}
.Text {
  font-weight: 400;
  font-size: 14px;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  align-self: center;
  text-align: center;
}

.ToolTipOverflownText {
  font-weight: 400;
  font-size: 14px;
  min-width: 0;
  white-space: nowrap;
}

.TextContainer {
  align-self: center;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.PriceTextContainer {
  align-self: center;
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: center;
}

.infoIcon {
  opacity: 0.7;
  align-self: center;
  margin-left: 0.5rem;
  width: 0.85rem !important;
}
.ToolTipWrapper {
  display: flex;
  padding: 0.5rem;
}
.ToolTipRightContainer {
  margin-left: 0.5rem;
}
.ToolTipTitleText {
  font-weight: 600;
  font-size: 14px !important;
  margin-bottom: 1rem;
}
.ToolTipValueText {
  font-weight: 100;
  margin-bottom: 1rem;
  font-size: 14px;
  margin-left: 1rem;
}
